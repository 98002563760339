/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "./authContext"
import { useRouter } from "next/router"
import * as React from "react"
import { createContext, useContext } from "react"

interface Context {
  error: boolean
}

const ServiceContext = createContext<Context>(null)

export function ServiceProvider({ children }) {
  const router = useRouter()
  const [error, setError] = React.useState(false)
  const { setSessionExpired } = useAuth()

  React.useEffect(() => {
    const { query } = router
    const serviceErrorStatus = query.serviceErrorStatus as string
    if (serviceErrorStatus === "401") {
      setSessionExpired(true)
    }
    //
    else if (["400", "500"].includes(serviceErrorStatus)) {
      setError(true)
    }
  }, [router])

  const value: Context = {
    error,
  }

  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  )
}

export function useServiceContext() {
  return useContext(ServiceContext)
}
