import { AuthProvider } from "../context/authContext"
import { LicenseProvider } from "../context/licenseContext"
import { ServiceProvider } from "../context/serviceContext"
import "../styles/global.css"
// import "@tremor/react/dist/esm/tremor.css"
import Head from "next/head"
import React from "react"
import "react-datepicker/dist/react-datepicker.css"

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Head>
        <link
          rel="shortcut icon"
          href="https://iamyqpnnibrsxhgaflmh.supabase.co/storage/v1/object/public/images-public/svg/coverdash_logo_shield_green.svg"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        ></meta>
        {/* General tags */}
        <meta
          key="description"
          property="description"
          content="Partner Dashboard"
        />
        <title key="title">Partner Dashboard</title>
        {/* OpenGraph tags */}
        <meta
          key="og:url"
          property="og:url"
          content="https://partner.coverdash.com"
        />
        <meta key="og:title" property="og:title" content="Partner Dashboard" />
        <meta
          key="og:description"
          property="og:description"
          content="Partner Dashboard"
        />
        {/* <meta key="og:image" property="og:image" content="" /> */}
        <meta key="og:type" property="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta
          key="twitter:title"
          property="twitter:title"
          content="Partner Dashboard"
        />
        <meta
          key="twitter:description"
          property="twitter:description"
          content="Partner Dashboard"
        />
        {/* <meta key="twitter:image" property="twitter:image" content="" /> */}
        <meta key="twitter:card" property="twitter:card" content="summary" />
        <title key="title">Coverdash</title>
      </Head>
      {/* <Script id="intercom-settings">
        {`
window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "b4h57lim"
};
`}
      </Script>
      <Script id="intercom-snippet">
        {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/b4h57lim';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script> */}
      <AuthProvider>
        <ServiceProvider>
          <LicenseProvider>
            <Component {...pageProps} />
          </LicenseProvider>
        </ServiceProvider>
      </AuthProvider>
    </>
  )
}

export default MyApp
