/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { AsYouType } from "libphonenumber-js"

dayjs.extend(utc)

export const APP_SERVICE_URL =
  process.env.APP_SERVICE_URL || process.env.NEXT_PUBLIC_APP_SERVICE_URL

export const NEXT_PUBLIC_VERCEL_ENV =
  process.env.NEXT_PUBLIC_VERCEL_ENV || "development"

export function dev_log(...args: any[]) {
  if (NEXT_PUBLIC_VERCEL_ENV !== "production") {
    console.log(...args)
  }
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function snakeCaseToSentence(errorCode: string): string {
  // Split the error code by underscores
  const words = errorCode.split("_")

  // Convert each word to title case
  const sentence = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  return sentence
}

export const otherPolicies = [
  "COMMERCIAL_PROPERTY",
  "CRIME",
  "FIDUCIARY",
  "WIND_HAIL",
  "EARTHQUAKE",
  "PROPERTY",
  "KIDNAP_AND_RANSOM",
  "INLAND_MARINE",
  "OCEAN_MARINE",
  "GLOBAL_PACKAGE",
  "DIFFERENCE_IN_CONDITION",
  "FLOOD",
  "UMBRELLA",
  "SURETY_BOND",
  "COMMERCIAL_AUTO",
  "ABUSE_MOLESTATION",
  "TECH_EO",
  "EQUIPMENT_BREAKDOWN",
  "TERRORISM",
  "MEDIA_LIABILITY",
  "TRANSACTIONAL_RISK",
  "WORKPLACE_VIOLENCE",
  "PRODUCTS_LIABILITY",
]

export function policyIsMgl(policyType: string) {
  return [
    "DIRECTORS_AND_OFFICERS",
    "EMPLOYMENT_PRACTICES_LIABILITY",
    "DIRECTORS_AND_OFFICERS_EMPLOYMENT_PRACTICES_LIABILITY",
  ].includes(policyType)
}

export const policies: any = {
  GENERAL_LIABILITY: {
    title: "General Liability",
    imageDefault: `/img/gl-default.jpg`,
    imageHover: `/img/gl.jpg`,
    excerpt: `Core financial protection from everyday workplace risks.`,
  },
  BUSINESS_OWNERS_POLICY: {
    title: "Business Owner's Policy",
    imageDefault: `/img/bop-default.jpg`,
    imageHover: `/img/bop.jpg`,
    excerpt: `Most common bundled coverage (liability, property, inventory, and more).`,
  },
  WORKERS_COMP: {
    title: "Workers' Compensation",
    imageDefault: `/img/wc-default.jpg`,
    imageHover: `/img/wc.jpg`,
    excerpt: `Covers injuries or illness as a direct result of employment.`,
  },
  CYBER: {
    title: "Cyber",
    imageDefault: `/img/cyber-default.jpg`,
    imageHover: `/img/cyber.jpg`,
    excerpt: `Protects against malicious cyberattacks, loss of data, and more.`,
  },
  MANAGEMENT: {
    title: "Management Liability",
    imageDefault: `/img/mgl-default.jpg`,
    imageHover: `/img/mgl.jpg`,
    excerpt: `Includes Directors & Officers, EPLI, Fiduciary, and Crime.`,
  },
  PROFESSIONAL_LIABILITY: {
    title: "Professional Liability",
    imageDefault: `/img/pl-default.jpg`,
    imageHover: `/img/pl.jpg`,
    excerpt: `Covers your business from customer legal disputes.`,
  },
}

export const managementPolicies: any = {
  DIRECTORS_AND_OFFICERS: {
    title: "Directors & Officers",
    imageDefault: `/img/d-and-o-default.jpg`,
    imageHover: `/img/d-and-o.jpg`,
    excerpt: `D&O insurance protects directors and officers from lawsuits.`,
  },
  EMPLOYMENT_PRACTICES_LIABILITY: {
    title: "Employment Practices Liability",
    imageDefault: `/img/epl-default.jpg`,
    imageHover: `/img/epl.jpg`,
    excerpt: `EPL insurance defends your business against employee claims.`,
  },
}

export const allPolicyCodes: {
  [key: string]: {
    code: string
    title: string
  }
} = {
  GENERAL_LIABILITY: {
    code: "GENERAL_LIABILITY",
    title: "General Liability",
  },
  PROFESSIONAL_LIABILITY: {
    code: "PROFESSIONAL_LIABILITY",
    title: "Professional Liability",
  },
  BUSINESS_OWNERS_POLICY: {
    code: "BUSINESS_OWNERS_POLICY",
    title: "Business Owner's Policy",
  },
  WORKERS_COMP: {
    code: "WORKERS_COMP",
    title: "Workers' Compensation",
  },
  CYBER: {
    code: "CYBER",
    title: "Cyber",
  },
  DIRECTORS_AND_OFFICERS: {
    code: "DIRECTORS_AND_OFFICERS",
    title: "Management Liability",
  },
  EMPLOYMENT_PRACTICES_LIABILITY: {
    code: "EMPLOYMENT_PRACTICES_LIABILITY",
    title: "Management Liability",
  },
  DIRECTORS_AND_OFFICERS_EMPLOYMENT_PRACTICES_LIABILITY: {
    code: "DIRECTORS_AND_OFFICERS_EMPLOYMENT_PRACTICES_LIABILITY",
    title: "Management Liability",
  },
  COMMERCIAL_PROPERTY: {
    code: "COMMERCIAL_PROPERTY",
    title: "Commercial Property",
  },
  CRIME: {
    code: "CRIME",
    title: "Crime",
  },
  FIDUCIARY: {
    code: "FIDUCIARY",
    title: "Fiduciary",
  },
  WIND_HAIL: {
    code: "WIND_HAIL",
    title: "Wind/Hail",
  },
  EARTHQUAKE: {
    code: "EARTHQUAKE",
    title: "Earthquake",
  },
  PROPERTY: {
    code: "PROPERTY",
    title: "Property",
  },
  KIDNAP_AND_RANSOM: {
    code: "KIDNAP_AND_RANSOM",
    title: "Kidnap and Ransom",
  },
  INLAND_MARINE: {
    code: "INLAND_MARINE",
    title: "Inland Marine",
  },
  OCEAN_MARINE: {
    code: "OCEAN_MARINE",
    title: "Ocean Marine",
  },
  GLOBAL_PACKAGE: {
    code: "GLOBAL_PACKAGE",
    title: "Global Package",
  },
  DIFFERENCE_IN_CONDITION: {
    code: "DIFFERENCE_IN_CONDITION",
    title: "Difference in Condition",
  },
  FLOOD: {
    code: "FLOOD",
    title: "Flood",
  },
  UMBRELLA: {
    code: "UMBRELLA",
    title: "Umbrella",
  },
  SURETY_BOND: {
    code: "SURETY_BOND",
    title: "Surety Bond",
  },
  COMMERCIAL_AUTO: {
    code: "COMMERCIAL_AUTO",
    title: "Commercial Auto",
  },
  ABUSE_MOLESTATION: {
    code: "ABUSE_MOLESTATION",
    title: "Abuse",
  },
  TECH_EO: {
    code: "TECH_EO",
    title: "Tech EO",
  },
  EQUIPMENT_BREAKDOWN: {
    code: "EQUIPMENT_BREAKDOWN",
    title: "Equipment Breakdown",
  },
  TERRORISM: {
    code: "TERRORISM",
    title: "Terrorism",
  },
  MEDIA_LIABILITY: {
    code: "MEDIA_LIABILITY",
    title: "Media Liability",
  },
  TRANSACTIONAL_RISK: {
    code: "TRANSACTIONAL_RISK",
    title: "Transactional Risk",
  },
  WORKPLACE_VIOLENCE: {
    code: "WORKPLACE_VIOLENCE",
    title: "Workplace Violence",
  },
  PRODUCTS_LIABILITY: {
    code: "PRODUCTS_LIABILITY",
    title: "Products Liability",
  },
}

export const classNames = (...classes: string[]) =>
  classes.filter(Boolean).join(" ")

export const getURL = () => {
  const url =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
      ? window.location.origin
      : `https://${process.env.NEXT_PUBLIC_BASE_URL}`

  return url
}

export function formatPhoneNumber(unformattedPhoneNumber: string) {
  return new AsYouType("US").input(unformattedPhoneNumber)
}

export function formatMoneyValue(_unformattedMoneyValue: any) {
  if (!_unformattedMoneyValue) {
    return ""
  }
  _unformattedMoneyValue = _unformattedMoneyValue.toString()
  // If input is just an empty string (meaning user is
  // trying to delete the value in the input),
  // return empty string
  if (_unformattedMoneyValue == "") {
    return ""
  }

  // Remove commas from existing value
  const _cleanedMoneyValue = _unformattedMoneyValue.replace(/,/g, "")

  // Only allow numbers to be input
  if (/^\d+$/.test(_cleanedMoneyValue)) {
    return parseInt(_cleanedMoneyValue).toLocaleString()
  } else {
    return _unformattedMoneyValue.slice(0, -1)
  }
}

export function getFirstDayOfLastQuarter() {
  const today = new Date()
  const currentMonth = today.getMonth()

  if (currentMonth >= 0 && currentMonth <= 2) {
    // Current month is between January and March (Q1)
    return new Date(today.getFullYear() - 1, 9, 1) // Return first day of previous October
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    // Current month is between April and June (Q2)
    return new Date(today.getFullYear(), 0, 1) // Return first day of January
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    // Current month is between July and September (Q3)
    return new Date(today.getFullYear(), 3, 1) // Return first day of April
  } else {
    // Current month is between October and December (Q4)
    return new Date(today.getFullYear(), 6, 1) // Return first day of July
  }
}

export function getLastDayOfLastQuarter() {
  const firstDayOfLastQuarter = getFirstDayOfLastQuarter()
  const lastDay = dayjs(firstDayOfLastQuarter).add(2, "month").endOf("month")
  // dev_log("lastDayOfLastQuarter", lastDay)
  return new Date(lastDay.utc().format("YYYY-MM-DD")) // Return the date in the format you prefer
}
