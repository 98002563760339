/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { adminUserIds } from "../constants/navigation"
import { dev_log } from "../utilities"
import auth0 from "auth0-js"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react"
import { useCookies } from "react-cookie"

export const CRUD_SERVICE_URL =
  process.env.NEXT_PUBLIC_CRUD_SERVICE_URL ?? process.env.CRUD_SERVICE_URL

export interface Auth0User {
  id: string
  email?: string
  firstName?: string
  lastName?: string
  businessName?: string
  idToken: string
  paymentStructure?: { type: string; amount: string; data_shown: string }
  metadata?: any
}

type Props = { children: React.ReactNode }
type CoverdashAuth = {
  idToken: string
  exp: number
}
const DATABASE_CONNECTION = "Username-Password-Authentication"

const AuthContext = createContext<{
  getCoverdashAuth: () => Promise<CoverdashAuth>
  user: Auth0User
  superUser: Auth0User
  sessionExpired: boolean
  setSessionExpired: (expired: boolean) => void
  parseHash: (hash?: string | undefined) => Promise<any>
  checkSession: () => Promise<any>
  loginWithPassword: (username: string, password: string) => Promise<any>
  loginWithGoogle: () => void
  signUp: (
    username: string,
    password: string,
    firstName: string | null,
    lastName: string | null
  ) => Promise<any>
  logout: () => void
  resetPassword: (email: string) => Promise<any>
}>(undefined as any)

export function AuthProvider({ children }: Props) {
  const router = useRouter()
  const [user, setUser] = React.useState<Auth0User>(null)
  const [superUser, setSuperUser] = React.useState<Auth0User>(null)
  const [sessionExpired, setSessionExpired] = React.useState<boolean>(false)
  const [cookies] = useCookies(["impersonatedUser"])
  const [originUrl, setOriginUrl] = React.useState<string>("")
  useEffect(() => {
    setOriginUrl(window.location.origin)
  }, [])

  async function fetchCrudService(
    method: "GET" | "POST" | "PATCH" | "DELETE",
    token: string,
    body: any = null,
    path: string
  ): Promise<any> {
    dev_log(`⏳ [${method}] 8083/${path}`, "(body)", body)
    let headers: any = {
      Authorization: "Bearer " + token,
    }
    if (["POST", "PATCH"].includes(method)) {
      headers = {
        ...headers,
        "Content-Type": "application/json",
      }
    }

    const res = await fetch(`${CRUD_SERVICE_URL}/${path}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    })

    const json = await res.json()

    const emojiForResponse = json.error || json.Error ? "❌" : "✅"
    dev_log(`${emojiForResponse} [${method}] 8083/${path}`, "(response) ", json)
    return json
  }

  async function updateUserLastLogin(token: string, userId: string) {
    const res = await fetchCrudService(
      "PATCH",
      token,
      {
        userId,
      },
      `quoteComponent/updateUserLastLogin`
    )

    return res
  }

  async function getUser(token: string) {
    const currentUser = (
      await fetchCrudService("GET", token, null, "user/getLoggedInUser")
    ).User

    const impersonatedUserId = cookies.impersonatedUser
    const isAdmin = Boolean(adminUserIds.includes(currentUser?.id))

    dev_log("isAdmin", isAdmin)
    if (isAdmin) {
      setSuperUser(currentUser)
    }

    let res
    if (isAdmin && impersonatedUserId) {
      res = await fetchCrudService(
        "POST",
        token,
        {
          id: impersonatedUserId,
        },
        "general/getUserById"
      )
    }
    //
    else {
      res = currentUser
    }

    return res
  }

  // const webAuth = useMemo(
  //   () =>
  //     new auth0.WebAuth({
  //       domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
  //       clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
  //       redirectUri: originUrl,
  //       audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  //       // responseType: "token",
  //       responseType: "id_token",
  //       scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
  //     }),
  //   []
  // )

  let webAuth

  if (typeof window !== "undefined") {
    webAuth = new auth0.WebAuth({
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
      redirectUri: window.location.origin,
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      responseType: "id_token",
      scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
    })
  }

  const getCoverdashAuth = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      const _coverdashAuth = JSON.parse(
        localStorage.getItem("coverdash-auth") || "null"
      )
      resolve(_coverdashAuth)
    }) as Promise<CoverdashAuth>
  }, [webAuth])

  const parseHash = useCallback(
    (hash: string | undefined) => {
      return new Promise((resolve, reject) => {
        webAuth.parseHash({ hash }, function (err, authResult) {
          if (err) {
            dev_log(err)
            resolve(null)
          }

          resolve(authResult)
        })
      })
    },
    [webAuth]
  )

  const resetPassword = useCallback(
    (email: string) => {
      return new Promise((resolve, reject) => {
        webAuth.changePassword(
          {
            connection: DATABASE_CONNECTION,
            email,
          },
          function (err, resp) {
            if (err) {
              dev_log(err)
              reject(err)
            } else {
              dev_log(resp)
              resolve(resp)
            }
          }
        )
      })
    },
    [webAuth]
  )

  const checkSession = useCallback(() => {
    dev_log({
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string,
      redirectUri: originUrl,
      audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
      // responseType: "token",
      responseType: "id_token",
      scope: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
    })
    return new Promise((resolve, reject) => {
      webAuth.checkSession(
        {
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          scope: "openid",
        },
        async (error, result) => {
          if (error) {
            setSessionExpired(true)
            reject(error)
            return
          }
          const _coverdashAuth = JSON.parse(
            localStorage.getItem("coverdash-auth") || "null"
          )

          if (
            !_coverdashAuth ||
            dayjs.unix(_coverdashAuth.exp).isBefore(dayjs())
          ) {
            // token expired
            setSessionExpired(true)
            resolve(null)
          }
          //
          else {
            result?.idToken &&
              localStorage.setItem(
                "coverdash-auth",
                JSON.stringify({
                  idToken: result.idToken,
                  exp: result.idTokenPayload?.exp,
                })
              )
            dev_log("idToken:", result.idToken)
            dev_log(
              "idTokenExpiry:",
              dayjs
                .unix(result.idTokenPayload.exp)
                .format("YYYY-MM-DD h:mm:ss A")
            )

            const { idToken } = result
            if (idToken) {
              setUser({
                firstName: result.idTokenPayload.metadata.first_name,
                lastName: result.idTokenPayload.metadata.last_name,
                email: result.idTokenPayload.metadata.email,
                businessName: result.idTokenPayload.metadata.business_name,
                paymentStructure:
                  result.idTokenPayload.metadata.payment_structure,
                id: result.idTokenPayload.sub,
                idToken: result.idToken,
                metadata: result.idTokenPayload.metadata,
              })
            } else {
              dev_log("ERROR:", "idToken not found")
            }
          }
          resolve(result)
        }
      )
    })
  }, [])

  React.useEffect(() => {
    const _coverdashAuth = JSON.parse(
      localStorage.getItem("coverdash-auth") || "null"
    )
    // fetchCrudService("GET", _coverdashAuth?.idToken, null, "general/checkUser")
    //   .then((res) => {
    //     console.log(res)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
    const hash = window.location.hash
    if (hash) {
      parseHash(hash)
        .then((res: any) => {
          dev_log("res", res)
          if (res?.idToken) {
            localStorage.setItem(
              "coverdash-auth",
              JSON.stringify({
                idToken: res.idToken,
                exp: res.idTokenPayload.exp,
              })
            )

            dev_log("res:", res)
            // dev_log("idToken:", res.idToken)
            // dev_log(
            //   "idTokenExpiry:",
            //   dayjs.unix(res.idTokenPayload.exp).format("YYYY-MM-DD h:mm:ss A")
            // )

            // update user last login
            try {
              setUser({
                firstName: res.idTokenPayload.metadata.first_name,
                lastName: res.idTokenPayload.metadata.last_name,
                email: res.idTokenPayload.metadata.email,
                businessName: res.idTokenPayload.metadata.business_name,
                paymentStructure: res.idTokenPayload.metadata.payment_structure,
                id: res.idTokenPayload.sub,
                idToken: res.idToken,
                metadata: res.idTokenPayload.metadata,
              })
            } catch (error) {
              dev_log("updateUserLastLogin error:", error)
            }

            // remove hash from URL
            window.history.replaceState({}, document.title, "/")

            // set User
            // const { idToken } = res
            // getUser(idToken).then((data) => {
            //   if (data.id) {
            //     setUser(data)
            //   } else {
            //     dev_log("ERROR:", data)
            //   }
            // })
          }
          //
          else {
            setSessionExpired(true)
          }
        })
        .catch((error) => {
          // dev_log("parseHash error:", error)
          setSessionExpired(true)
        })
    }
    //
    else if (_coverdashAuth) {
      checkSession().catch((error) => {
        // dev_log("checkSession error:", error)
        setSessionExpired(true)
      })
    }
    //
    else {
      if (window.location.pathname !== "/unsubscribe") {
        router.push("/login")
      }
    }
  }, [])

  const loginWithPassword = useCallback(
    (username: string, password: string) => {
      const urlParams = new URLSearchParams(window.location.search)
      const stateParam = urlParams.get("state") || ""
      return new Promise((resolve, reject) => {
        webAuth.login(
          {
            username,
            password,
            realm: DATABASE_CONNECTION,
            state: stateParam,
            redirectUri: originUrl,
          },
          async (error, result) => {
            if (error) {
              console.error(error)
              reject(error)
              return
            }
            resolve(result)
          }
        )
      })
    },
    [webAuth]
  )

  const loginWithGoogle = useCallback(() => {
    webAuth.authorize({ connection: "google-oauth2" })
  }, [webAuth])

  const signUp = useCallback(
    (
      username: string,
      password: string,
      firstName: string | null = null,
      lastName: string | null = null
    ) => {
      return new Promise((resolve, reject) => {
        webAuth.signup(
          {
            connection: DATABASE_CONNECTION,
            password: password,
            email: username,
            userMetadata: {
              firstName,
              lastName,
            },
          },
          async (error, result) => {
            if (error) {
              reject(error)
              return
            }
            resolve(result)
            // const res = await loginWithPassword(username, password)
            // resolve(res)
          }
        )
      })
    },
    [webAuth]
  )

  const logout = useCallback(() => {
    localStorage.removeItem("coverdash-auth")
    webAuth.logout({
      returnTo: `${originUrl}/login`,
      clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    })
  }, [webAuth])

  const value = useMemo(
    () => ({
      getCoverdashAuth,
      user,
      superUser,
      sessionExpired,
      setSessionExpired,
      parseHash,
      checkSession,
      loginWithPassword,
      loginWithGoogle,
      signUp,
      logout,
      resetPassword,
    }),
    [
      getCoverdashAuth,
      user,
      superUser,
      sessionExpired,
      setSessionExpired,
      checkSession,
      loginWithGoogle,
      loginWithPassword,
      parseHash,
      signUp,
      logout,
      resetPassword,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
